















/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class DashboardCoreView extends Vue {
  Transition = '';

  prevHeight = '';

  mounted() {
    this.$router.beforeEach((to, from, next) => {
      this.Transition = to.meta.transition || from.meta.transition || '';
      next();
    });
  }

  beforeLeave(element: HTMLElement) {
    this.prevHeight = getComputedStyle(element).height;
  }

  enter(element: HTMLElement) {
    const { height } = getComputedStyle(element);

    element.style.height = this.prevHeight;

    setTimeout(() => {
      element.style.height = height;
    });
  }

  afterEnter(element: HTMLElement) {
    element.style.height = 'auto';
  }
}
